import React from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import Typography from '../../atoms/Typography'
import styles from './style.module.scss'
import Icon from '../../atoms/Icon'

type NotificationProps = {
  date: string
  sendAt: string
  onClick: (salesDate: string) => void
} & React.StyledProps

const Notification: React.FC<NotificationProps> = ({
  className,
  date,
  sendAt,
  onClick,
}: NotificationProps) => {
  dayjs.locale('ja')
  return (
    <div
      className={clsx(className, styles.root)}
      onClick={(): void => onClick(date)}
    >
      <div className={styles.container}>
        <span className={styles.date}>
          {/* FIXME: 共通化して、他の同じ処理も置き換える */}
          {dayjs(date).format('YYYY.MM.DD(ddd)')}
        </span>
        <div className={styles.reportTime}>
          <span>受信日時:</span>
          <span>{dayjs(sendAt).format('YYYY.MM.DD(ddd) HH:mm')}</span>
        </div>
      </div>
      <div className={styles.confirm}>
        <Typography className={styles.confirmText}>確認する</Typography>
        <Icon icon="next" />
      </div>
    </div>
  )
}

export default Notification
