/* tslint:disable */
/* eslint-disable */
/**
 * Report App API
 * Report App API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@wed.company
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ConsistencyPrecheckItem
 */
export interface ConsistencyPrecheckItem {
    /**
     * manual_input_form_item_id
     * @type {string}
     * @memberof ConsistencyPrecheckItem
     */
    id: string;
    /**
     * 算出項目名
     * @type {string}
     * @memberof ConsistencyPrecheckItem
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ConsistencyResult
 */
export interface ConsistencyResult {
    /**
     * 
     * @type {ConsistencyResultEnum}
     * @memberof ConsistencyResult
     */
    result: ConsistencyResultEnum;
}
/**
 * 整合性チェックの結果を表す。 - `in_progress`: 処理中 - `pass`: 結果に関わらず通過 - `retry`: 画像の撮影からやり直し - `failed`: OCR失敗 
 * @export
 * @enum {string}
 */

export enum ConsistencyResultEnum {
    InProgress = 'in_progress',
    Pass = 'pass',
    Retry = 'retry',
    Failed = 'failed'
}

/**
 * 
 * @export
 * @interface ConsistencyStatement
 */
export interface ConsistencyStatement {
    /**
     * consistency_statement_id
     * @type {string}
     * @memberof ConsistencyStatement
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ConsistencyStatement
     */
    name: string;
    /**
     * 
     * @type {Array<ConsistencyPrecheckItem>}
     * @memberof ConsistencyStatement
     */
    leftItems: Array<ConsistencyPrecheckItem>;
    /**
     * 
     * @type {Array<ConsistencyPrecheckItem>}
     * @memberof ConsistencyStatement
     */
    rightItems: Array<ConsistencyPrecheckItem>;
}
/**
 * 
 * @export
 * @interface ConsistencyStatements
 */
export interface ConsistencyStatements {
    /**
     * 
     * @type {Array<ConsistencyStatement>}
     * @memberof ConsistencyStatements
     */
    statements: Array<ConsistencyStatement>;
}
/**
 * エラーコード。アプリ側でエラーコードを見て判断
 * @export
 * @enum {string}
 */

export enum ErrorCodeEnum {
    InternalServerError = 'internal_server_error',
    NotFound = 'not_found',
    OutOfService = 'out_of_service',
    SalesdateAlreadyExists = 'salesdate_already_exists',
    InvalidRequestBody = 'invalid_request_body',
    InvalidTenantCode = 'invalid_tenant_code',
    InvalidTenantStatus = 'invalid_tenant_status',
    InvalidSalesDate = 'invalid_sales_date',
    InvalidMedia = 'invalid_media',
    InvalidInput = 'invalid_input',
    InvalidSession = 'invalid_session',
    InvalidFormat = 'invalid_format',
    AuthorizationError = 'authorization_error',
    InvalidToken = 'invalid_token',
    SubmitValidate = 'submit_validate',
    AlreadySubmitted = 'already_submitted'
}

/**
 * 
 * @export
 * @interface Form
 */
export interface Form {
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Form
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    label: string;
    /**
     * 
     * @type {FormTypeEnum}
     * @memberof Form
     */
    type: FormTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    prefix?: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    suffix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Form
     */
    required: boolean;
}
/**
 * 
 * @export
 * @interface FormAnswer
 */
export interface FormAnswer {
    /**
     * 
     * @type {string}
     * @memberof FormAnswer
     */
    formId: string;
    /**
     * 
     * @type {string}
     * @memberof FormAnswer
     */
    value: string | null;
}
/**
 * 
 * @export
 * @interface FormAnswers
 */
export interface FormAnswers {
    /**
     * 
     * @type {Array<FormAnswer>}
     * @memberof FormAnswers
     */
    answers: Array<FormAnswer>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FormTypeEnum {
    Text = 'text',
    Number = 'number',
    Date = 'date',
    Email = 'email'
}

/**
 * 
 * @export
 * @interface Forms
 */
export interface Forms {
    /**
     * 
     * @type {Array<Form>}
     * @memberof Forms
     */
    forms: Array<Form>;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    data: string;
}
/**
 * 
 * @export
 * @interface Images
 */
export interface Images {
    /**
     * 
     * @type {Array<Image>}
     * @memberof Images
     */
    images: Array<Image>;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {ErrorCodeEnum}
     * @memberof ModelError
     */
    code: ErrorCodeEnum;
    /**
     * エラーメッセージ。付加的な意味。
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * テナントに通知するメッセージ
 * @export
 * @interface NotificationToTenant
 */
export interface NotificationToTenant {
    /**
     * 
     * @type {string}
     * @memberof NotificationToTenant
     */
    salesDate: string;
    /**
     * 内容
     * @type {string}
     * @memberof NotificationToTenant
     */
    content: string;
    /**
     * 最終更新時間
     * @type {string}
     * @memberof NotificationToTenant
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface PdfUri
 */
export interface PdfUri {
    /**
     * 
     * @type {number}
     * @memberof PdfUri
     */
    index: number;
    /**
     * gcs signed uri
     * @type {string}
     * @memberof PdfUri
     */
    uri: string;
}
/**
 * 売上の報告ステータス - waiting: 報告待ち - reported: 報告済み - imageResent: 画像再送信済み - finalizedReportSubmitted: 確定値入力済み - finalizedReportSynced: csv(確定値含む)連携済み 
 * @export
 * @enum {string}
 */

export enum ReportStatusEnum {
    Waiting = 'waiting',
    Reported = 'reported',
    ImageResent = 'imageResent',
    FinalizedReportSubmitted = 'finalizedReportSubmitted',
    FinalizedReportSynced = 'finalizedReportSynced'
}

/**
 * sales date
 * @export
 * @interface SalesDate
 */
export interface SalesDate {
    /**
     * 
     * @type {string}
     * @memberof SalesDate
     */
    date: string;
}
/**
 * 売上報告の伝達事項
 * @export
 * @interface SalesReportMessage
 */
export interface SalesReportMessage {
    /**
     * 
     * @type {string}
     * @memberof SalesReportMessage
     */
    message: string;
}
/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    code: string;
}
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    id: string;
    /**
     * テナントコード
     * @type {string}
     * @memberof Tenant
     */
    tenantCode: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    tenantName: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    storeCode: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    storeName: string;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    ocrEnabled: boolean;
    /**
     * PCからの報告が可能かどうか
     * @type {boolean}
     * @memberof Tenant
     */
    pcEnabled: boolean;
}
/**
 * テナントとテナント詳細とテナントの売上報告履歴
 * @export
 * @interface TenantAndTenantSalesReportHistory
 */
export interface TenantAndTenantSalesReportHistory {
    /**
     * 
     * @type {Tenant}
     * @memberof TenantAndTenantSalesReportHistory
     */
    tenant: Tenant;
    /**
     * 
     * @type {Array<TenantSalesReport>}
     * @memberof TenantAndTenantSalesReportHistory
     */
    tenantSalesReportHistory?: Array<TenantSalesReport>;
    /**
     * 
     * @type {TenantDetail}
     * @memberof TenantAndTenantSalesReportHistory
     */
    tenantDetail?: TenantDetail;
}
/**
 * テナント詳細
 * @export
 * @interface TenantDetail
 */
export interface TenantDetail {
    /**
     * 担当者の名前
     * @type {string}
     * @memberof TenantDetail
     */
    staffName?: string;
    /**
     * 担当者の電話番号
     * @type {string}
     * @memberof TenantDetail
     */
    staffTel?: string;
    /**
     * 営業開始時間
     * @type {string}
     * @memberof TenantDetail
     */
    openingTime?: string;
    /**
     * 営業終了時間
     * @type {string}
     * @memberof TenantDetail
     */
    closingTime?: string;
    /**
     * 契約開始日
     * @type {string}
     * @memberof TenantDetail
     */
    contractStartDate?: string;
    /**
     * 契約終了日
     * @type {string}
     * @memberof TenantDetail
     */
    contractEndDate?: string;
    /**
     * WebページのURL
     * @type {string}
     * @memberof TenantDetail
     */
    url?: string;
    /**
     * テナントに関する個別メモ
     * @type {string}
     * @memberof TenantDetail
     */
    note?: string;
    /**
     * テナント詳細の更新時間
     * @type {string}
     * @memberof TenantDetail
     */
    updatedAt?: string;
}
/**
 * テナントの売上報告
 * @export
 * @interface TenantSalesReport
 */
export interface TenantSalesReport {
    /**
     * 
     * @type {string}
     * @memberof TenantSalesReport
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSalesReport
     */
    date: string;
    /**
     * 
     * @type {ReportStatusEnum}
     * @memberof TenantSalesReport
     */
    reportStatus: ReportStatusEnum;
    /**
     * 報告時間
     * @type {string}
     * @memberof TenantSalesReport
     */
    updatedAt: string | null;
}
/**
 * 
 * @export
 * @interface TenantUser
 */
export interface TenantUser {
    /**
     * 
     * @type {string}
     * @memberof TenantUser
     */
    email: string;
}

/**
 * GeneralApi - axios parameter creator
 * @export
 */
export const GeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アプリが使用時間かどうかを返す
         * @summary アプリが使用できる時間なのかを返す。(7:00-26:30の間のみ使用可)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActive: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在のSessionのレジの結果を返す。
         * @summary 整合性チェックの結果を取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsistencyResults: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('getConsistencyResults', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/consistency_result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 整合性計算ロジックの取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsistencyStatements: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('getConsistencyStatements', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/consistency_statements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * フォーム取得API
         * @summary 入力項目取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputForm: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('getInputForm', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/input-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * デベロッパーからテナントに通知するメッセージ一覧を取得(sales_dateで降順)
         * @summary メッセージ一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * null
         * @summary 売上報告履歴の取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPcSalesReportHistory: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('getPcSalesReportHistory', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/pc/sales-report-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テナント認証API(pdfアップロードアプリ用)
         * @summary Auth by tenant id
         * @param {string} tenantId テナントID
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPcTenantAuth: async (tenantId: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getPcTenantAuth', 'tenantId', tenantId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getPcTenantAuth', 'email', email)
            const localVarPath = `/pc/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantId !== undefined) {
                localVarQueryParameter['tenant_id'] = tenantId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テナント認証API
         * @summary Auth by tenant code
         * @param {string} tenantCode 企業コード(3桁) + 店舗コード(3桁) + テナントコード(店舗コード+ブロックコード) (7桁)の合計13桁
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantAuth: async (tenantCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCode' is not null or undefined
            assertParamExists('getTenantAuth', 'tenantCode', tenantCode)
            const localVarPath = `/app/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantCode !== undefined) {
                localVarQueryParameter['tenant_code'] = tenantCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 複数レジで2台目以降の情報登録前に呼び、新しいレジを作成する
         * @summary 売上報告途中の売上報告日にレジを追加
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppSalesdateRegisters: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('postAppSalesdateRegisters', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/salesdate/registers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 報告済みの売上報告日にレジを追加する
         * @summary 報告済みの売上報告日にレジを追加
         * @param {string} salesDateId 売上報告日ID
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppSalesdateSalesDateIdRegisters: async (salesDateId: string, xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'salesDateId' is not null or undefined
            assertParamExists('postAppSalesdateSalesDateIdRegisters', 'salesDateId', salesDateId)
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('postAppSalesdateSalesDateIdRegisters', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/salesdate/{sales_date_id}/registers`
                .replace(`{${"sales_date_id"}}`, encodeURIComponent(String(salesDateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 画像送信API
         * @summary 画像送信
         * @param {string} xSESSIONID セッションID
         * @param {Images} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImages: async (xSESSIONID: string, images?: Images, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('postImages', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(images, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * フォーム回答API
         * @summary 入力項目作成
         * @param {FormAnswers} [formAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInputForm: async (formAnswers?: FormAnswers, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/input-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formAnswers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PDF送信API
         * @summary PDF送信
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPcPdfs: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('postPcPdfs', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/pc/pdfs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 画像再送信API
         * @summary 画像再送信
         * @param {string} xSESSIONID セッションID
         * @param {Images} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResentImages: async (xSESSIONID: string, images?: Images, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('postResentImages', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/resent-images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(images, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 売上日登録API 複数レジある場合もこのAPIで1台目が作成される。(どの売上に対しても必須のため) ※すでに未報告の売上日が登録されている場合、その売上日のデータは消え新しいデータで上書きされます。 
         * @summary 売上日登録
         * @param {string} xSESSIONID セッションID
         * @param {SalesDate} salesDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesDate: async (xSESSIONID: string, salesDate: SalesDate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('postSalesDate', 'xSESSIONID', xSESSIONID)
            // verify required parameter 'salesDate' is not null or undefined
            assertParamExists('postSalesDate', 'salesDate', salesDate)
            const localVarPath = `/app/salesdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesDate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 売上報告時に叩く。 売上日に複数のレジがある場合、合算した値を送信する。
         * @summary 売上報告API
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubmit: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('postSubmit', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 売上報告メモ更新API
         * @summary 売上報告メモ更新
         * @param {string} xSESSIONID セッションID
         * @param {SalesReportMessage} [salesReportMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesReportMessage: async (xSESSIONID: string, salesReportMessage?: SalesReportMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('putSalesReportMessage', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/sales_report_messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesReportMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * boba-pc User情報登録
         * @summary boba-pc User情報登録
         * @param {TenantUser} tenantUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantUserSignUp: async (tenantUser: TenantUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantUser' is not null or undefined
            assertParamExists('tenantUserSignUp', 'tenantUser', tenantUser)
            const localVarPath = `/app/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * アプリが使用時間かどうかを返す
         * @summary アプリが使用できる時間なのかを返す。(7:00-26:30の間のみ使用可)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActive(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 現在のSessionのレジの結果を返す。
         * @summary 整合性チェックの結果を取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsistencyResults(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsistencyResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsistencyResults(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 整合性計算ロジックの取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsistencyStatements(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsistencyStatements>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsistencyStatements(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * フォーム取得API
         * @summary 入力項目取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInputForm(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Forms>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInputForm(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * デベロッパーからテナントに通知するメッセージ一覧を取得(sales_dateで降順)
         * @summary メッセージ一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationToTenant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * null
         * @summary 売上報告履歴の取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPcSalesReportHistory(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAndTenantSalesReportHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPcSalesReportHistory(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * テナント認証API(pdfアップロードアプリ用)
         * @summary Auth by tenant id
         * @param {string} tenantId テナントID
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPcTenantAuth(tenantId: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPcTenantAuth(tenantId, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * テナント認証API
         * @summary Auth by tenant code
         * @param {string} tenantCode 企業コード(3桁) + 店舗コード(3桁) + テナントコード(店舗コード+ブロックコード) (7桁)の合計13桁
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantAuth(tenantCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAndTenantSalesReportHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantAuth(tenantCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 複数レジで2台目以降の情報登録前に呼び、新しいレジを作成する
         * @summary 売上報告途中の売上報告日にレジを追加
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppSalesdateRegisters(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppSalesdateRegisters(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 報告済みの売上報告日にレジを追加する
         * @summary 報告済みの売上報告日にレジを追加
         * @param {string} salesDateId 売上報告日ID
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppSalesdateSalesDateIdRegisters(salesDateId: string, xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppSalesdateSalesDateIdRegisters(salesDateId, xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 画像送信API
         * @summary 画像送信
         * @param {string} xSESSIONID セッションID
         * @param {Images} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postImages(xSESSIONID: string, images?: Images, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postImages(xSESSIONID, images, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * フォーム回答API
         * @summary 入力項目作成
         * @param {FormAnswers} [formAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInputForm(formAnswers?: FormAnswers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInputForm(formAnswers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PDF送信API
         * @summary PDF送信
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPcPdfs(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PdfUri>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPcPdfs(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 画像再送信API
         * @summary 画像再送信
         * @param {string} xSESSIONID セッションID
         * @param {Images} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postResentImages(xSESSIONID: string, images?: Images, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postResentImages(xSESSIONID, images, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 売上日登録API 複数レジある場合もこのAPIで1台目が作成される。(どの売上に対しても必須のため) ※すでに未報告の売上日が登録されている場合、その売上日のデータは消え新しいデータで上書きされます。 
         * @summary 売上日登録
         * @param {string} xSESSIONID セッションID
         * @param {SalesDate} salesDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesDate(xSESSIONID: string, salesDate: SalesDate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesDate(xSESSIONID, salesDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 売上報告時に叩く。 売上日に複数のレジがある場合、合算した値を送信する。
         * @summary 売上報告API
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSubmit(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSubmit(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 売上報告メモ更新API
         * @summary 売上報告メモ更新
         * @param {string} xSESSIONID セッションID
         * @param {SalesReportMessage} [salesReportMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSalesReportMessage(xSESSIONID: string, salesReportMessage?: SalesReportMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSalesReportMessage(xSESSIONID, salesReportMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * boba-pc User情報登録
         * @summary boba-pc User情報登録
         * @param {TenantUser} tenantUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantUserSignUp(tenantUser: TenantUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantUserSignUp(tenantUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralApiFp(configuration)
    return {
        /**
         * アプリが使用時間かどうかを返す
         * @summary アプリが使用できる時間なのかを返す。(7:00-26:30の間のみ使用可)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActive(options?: any): AxiosPromise<void> {
            return localVarFp.getActive(options).then((request) => request(axios, basePath));
        },
        /**
         * 現在のSessionのレジの結果を返す。
         * @summary 整合性チェックの結果を取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsistencyResults(xSESSIONID: string, options?: any): AxiosPromise<ConsistencyResult> {
            return localVarFp.getConsistencyResults(xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 整合性計算ロジックの取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsistencyStatements(xSESSIONID: string, options?: any): AxiosPromise<ConsistencyStatements> {
            return localVarFp.getConsistencyStatements(xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * フォーム取得API
         * @summary 入力項目取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputForm(xSESSIONID: string, options?: any): AxiosPromise<Forms> {
            return localVarFp.getInputForm(xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * デベロッパーからテナントに通知するメッセージ一覧を取得(sales_dateで降順)
         * @summary メッセージ一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(options?: any): AxiosPromise<Array<NotificationToTenant>> {
            return localVarFp.getNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * null
         * @summary 売上報告履歴の取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPcSalesReportHistory(xSESSIONID: string, options?: any): AxiosPromise<TenantAndTenantSalesReportHistory> {
            return localVarFp.getPcSalesReportHistory(xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * テナント認証API(pdfアップロードアプリ用)
         * @summary Auth by tenant id
         * @param {string} tenantId テナントID
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPcTenantAuth(tenantId: string, email: string, options?: any): AxiosPromise<void> {
            return localVarFp.getPcTenantAuth(tenantId, email, options).then((request) => request(axios, basePath));
        },
        /**
         * テナント認証API
         * @summary Auth by tenant code
         * @param {string} tenantCode 企業コード(3桁) + 店舗コード(3桁) + テナントコード(店舗コード+ブロックコード) (7桁)の合計13桁
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantAuth(tenantCode: string, options?: any): AxiosPromise<TenantAndTenantSalesReportHistory> {
            return localVarFp.getTenantAuth(tenantCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 複数レジで2台目以降の情報登録前に呼び、新しいレジを作成する
         * @summary 売上報告途中の売上報告日にレジを追加
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppSalesdateRegisters(xSESSIONID: string, options?: any): AxiosPromise<void> {
            return localVarFp.postAppSalesdateRegisters(xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * 報告済みの売上報告日にレジを追加する
         * @summary 報告済みの売上報告日にレジを追加
         * @param {string} salesDateId 売上報告日ID
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppSalesdateSalesDateIdRegisters(salesDateId: string, xSESSIONID: string, options?: any): AxiosPromise<void> {
            return localVarFp.postAppSalesdateSalesDateIdRegisters(salesDateId, xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * 画像送信API
         * @summary 画像送信
         * @param {string} xSESSIONID セッションID
         * @param {Images} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImages(xSESSIONID: string, images?: Images, options?: any): AxiosPromise<void> {
            return localVarFp.postImages(xSESSIONID, images, options).then((request) => request(axios, basePath));
        },
        /**
         * フォーム回答API
         * @summary 入力項目作成
         * @param {FormAnswers} [formAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInputForm(formAnswers?: FormAnswers, options?: any): AxiosPromise<void> {
            return localVarFp.postInputForm(formAnswers, options).then((request) => request(axios, basePath));
        },
        /**
         * PDF送信API
         * @summary PDF送信
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPcPdfs(xSESSIONID: string, options?: any): AxiosPromise<Array<PdfUri>> {
            return localVarFp.postPcPdfs(xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * 画像再送信API
         * @summary 画像再送信
         * @param {string} xSESSIONID セッションID
         * @param {Images} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResentImages(xSESSIONID: string, images?: Images, options?: any): AxiosPromise<void> {
            return localVarFp.postResentImages(xSESSIONID, images, options).then((request) => request(axios, basePath));
        },
        /**
         * 売上日登録API 複数レジある場合もこのAPIで1台目が作成される。(どの売上に対しても必須のため) ※すでに未報告の売上日が登録されている場合、その売上日のデータは消え新しいデータで上書きされます。 
         * @summary 売上日登録
         * @param {string} xSESSIONID セッションID
         * @param {SalesDate} salesDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesDate(xSESSIONID: string, salesDate: SalesDate, options?: any): AxiosPromise<void> {
            return localVarFp.postSalesDate(xSESSIONID, salesDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 売上報告時に叩く。 売上日に複数のレジがある場合、合算した値を送信する。
         * @summary 売上報告API
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubmit(xSESSIONID: string, options?: any): AxiosPromise<void> {
            return localVarFp.postSubmit(xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * 売上報告メモ更新API
         * @summary 売上報告メモ更新
         * @param {string} xSESSIONID セッションID
         * @param {SalesReportMessage} [salesReportMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesReportMessage(xSESSIONID: string, salesReportMessage?: SalesReportMessage, options?: any): AxiosPromise<void> {
            return localVarFp.putSalesReportMessage(xSESSIONID, salesReportMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * boba-pc User情報登録
         * @summary boba-pc User情報登録
         * @param {TenantUser} tenantUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantUserSignUp(tenantUser: TenantUser, options?: any): AxiosPromise<void> {
            return localVarFp.tenantUserSignUp(tenantUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI {
    /**
     * アプリが使用時間かどうかを返す
     * @summary アプリが使用できる時間なのかを返す。(7:00-26:30の間のみ使用可)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getActive(options?: any) {
        return GeneralApiFp(this.configuration).getActive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在のSessionのレジの結果を返す。
     * @summary 整合性チェックの結果を取得
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getConsistencyResults(xSESSIONID: string, options?: any) {
        return GeneralApiFp(this.configuration).getConsistencyResults(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 整合性計算ロジックの取得
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getConsistencyStatements(xSESSIONID: string, options?: any) {
        return GeneralApiFp(this.configuration).getConsistencyStatements(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * フォーム取得API
     * @summary 入力項目取得
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getInputForm(xSESSIONID: string, options?: any) {
        return GeneralApiFp(this.configuration).getInputForm(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * デベロッパーからテナントに通知するメッセージ一覧を取得(sales_dateで降順)
     * @summary メッセージ一覧を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getNotifications(options?: any) {
        return GeneralApiFp(this.configuration).getNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * null
     * @summary 売上報告履歴の取得
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getPcSalesReportHistory(xSESSIONID: string, options?: any) {
        return GeneralApiFp(this.configuration).getPcSalesReportHistory(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テナント認証API(pdfアップロードアプリ用)
     * @summary Auth by tenant id
     * @param {string} tenantId テナントID
     * @param {string} email メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getPcTenantAuth(tenantId: string, email: string, options?: any) {
        return GeneralApiFp(this.configuration).getPcTenantAuth(tenantId, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テナント認証API
     * @summary Auth by tenant code
     * @param {string} tenantCode 企業コード(3桁) + 店舗コード(3桁) + テナントコード(店舗コード+ブロックコード) (7桁)の合計13桁
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getTenantAuth(tenantCode: string, options?: any) {
        return GeneralApiFp(this.configuration).getTenantAuth(tenantCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 複数レジで2台目以降の情報登録前に呼び、新しいレジを作成する
     * @summary 売上報告途中の売上報告日にレジを追加
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public postAppSalesdateRegisters(xSESSIONID: string, options?: any) {
        return GeneralApiFp(this.configuration).postAppSalesdateRegisters(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 報告済みの売上報告日にレジを追加する
     * @summary 報告済みの売上報告日にレジを追加
     * @param {string} salesDateId 売上報告日ID
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public postAppSalesdateSalesDateIdRegisters(salesDateId: string, xSESSIONID: string, options?: any) {
        return GeneralApiFp(this.configuration).postAppSalesdateSalesDateIdRegisters(salesDateId, xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 画像送信API
     * @summary 画像送信
     * @param {string} xSESSIONID セッションID
     * @param {Images} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public postImages(xSESSIONID: string, images?: Images, options?: any) {
        return GeneralApiFp(this.configuration).postImages(xSESSIONID, images, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * フォーム回答API
     * @summary 入力項目作成
     * @param {FormAnswers} [formAnswers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public postInputForm(formAnswers?: FormAnswers, options?: any) {
        return GeneralApiFp(this.configuration).postInputForm(formAnswers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PDF送信API
     * @summary PDF送信
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public postPcPdfs(xSESSIONID: string, options?: any) {
        return GeneralApiFp(this.configuration).postPcPdfs(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 画像再送信API
     * @summary 画像再送信
     * @param {string} xSESSIONID セッションID
     * @param {Images} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public postResentImages(xSESSIONID: string, images?: Images, options?: any) {
        return GeneralApiFp(this.configuration).postResentImages(xSESSIONID, images, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 売上日登録API 複数レジある場合もこのAPIで1台目が作成される。(どの売上に対しても必須のため) ※すでに未報告の売上日が登録されている場合、その売上日のデータは消え新しいデータで上書きされます。 
     * @summary 売上日登録
     * @param {string} xSESSIONID セッションID
     * @param {SalesDate} salesDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public postSalesDate(xSESSIONID: string, salesDate: SalesDate, options?: any) {
        return GeneralApiFp(this.configuration).postSalesDate(xSESSIONID, salesDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 売上報告時に叩く。 売上日に複数のレジがある場合、合算した値を送信する。
     * @summary 売上報告API
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public postSubmit(xSESSIONID: string, options?: any) {
        return GeneralApiFp(this.configuration).postSubmit(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 売上報告メモ更新API
     * @summary 売上報告メモ更新
     * @param {string} xSESSIONID セッションID
     * @param {SalesReportMessage} [salesReportMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public putSalesReportMessage(xSESSIONID: string, salesReportMessage?: SalesReportMessage, options?: any) {
        return GeneralApiFp(this.configuration).putSalesReportMessage(xSESSIONID, salesReportMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * boba-pc User情報登録
     * @summary boba-pc User情報登録
     * @param {TenantUser} tenantUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public tenantUserSignUp(tenantUser: TenantUser, options?: any) {
        return GeneralApiFp(this.configuration).tenantUserSignUp(tenantUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainingApi - axios parameter creator
 * @export
 */
export const TrainingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 整合性計算ロジックの取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsistencyStatements: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('getConsistencyStatements', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/consistency_statements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * フォーム取得API
         * @summary 入力項目取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputForm: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('getInputForm', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/input-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 研修用手入力
         * @param {FormAnswers} [formAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppTrainingInputForm: async (formAnswers?: FormAnswers, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/training/input-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formAnswers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 研修終了送信
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppTrainingSubmit: async (xSESSIONID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSESSIONID' is not null or undefined
            assertParamExists('putAppTrainingSubmit', 'xSESSIONID', xSESSIONID)
            const localVarPath = `/app/training/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xSESSIONID !== undefined && xSESSIONID !== null) {
                localVarHeaderParameter['X-SESSION-ID'] = String(xSESSIONID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainingApi - functional programming interface
 * @export
 */
export const TrainingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 整合性計算ロジックの取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsistencyStatements(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsistencyStatements>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsistencyStatements(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * フォーム取得API
         * @summary 入力項目取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInputForm(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Forms>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInputForm(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 研修用手入力
         * @param {FormAnswers} [formAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppTrainingInputForm(formAnswers?: FormAnswers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppTrainingInputForm(formAnswers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 研修終了送信
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAppTrainingSubmit(xSESSIONID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAppTrainingSubmit(xSESSIONID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrainingApi - factory interface
 * @export
 */
export const TrainingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainingApiFp(configuration)
    return {
        /**
         * 
         * @summary 整合性計算ロジックの取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsistencyStatements(xSESSIONID: string, options?: any): AxiosPromise<ConsistencyStatements> {
            return localVarFp.getConsistencyStatements(xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * フォーム取得API
         * @summary 入力項目取得
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputForm(xSESSIONID: string, options?: any): AxiosPromise<Forms> {
            return localVarFp.getInputForm(xSESSIONID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 研修用手入力
         * @param {FormAnswers} [formAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppTrainingInputForm(formAnswers?: FormAnswers, options?: any): AxiosPromise<void> {
            return localVarFp.postAppTrainingInputForm(formAnswers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 研修終了送信
         * @param {string} xSESSIONID セッションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppTrainingSubmit(xSESSIONID: string, options?: any): AxiosPromise<void> {
            return localVarFp.putAppTrainingSubmit(xSESSIONID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainingApi - object-oriented interface
 * @export
 * @class TrainingApi
 * @extends {BaseAPI}
 */
export class TrainingApi extends BaseAPI {
    /**
     * 
     * @summary 整合性計算ロジックの取得
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public getConsistencyStatements(xSESSIONID: string, options?: any) {
        return TrainingApiFp(this.configuration).getConsistencyStatements(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * フォーム取得API
     * @summary 入力項目取得
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public getInputForm(xSESSIONID: string, options?: any) {
        return TrainingApiFp(this.configuration).getInputForm(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 研修用手入力
     * @param {FormAnswers} [formAnswers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public postAppTrainingInputForm(formAnswers?: FormAnswers, options?: any) {
        return TrainingApiFp(this.configuration).postAppTrainingInputForm(formAnswers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 研修終了送信
     * @param {string} xSESSIONID セッションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public putAppTrainingSubmit(xSESSIONID: string, options?: any) {
        return TrainingApiFp(this.configuration).putAppTrainingSubmit(xSESSIONID, options).then((request) => request(this.axios, this.basePath));
    }
}


