export const topPath = '/'
export const topWithClearDataPath = '/?clear=1'
export const readerPath = '/reader'
export const salesDatePath = '/sales-date/input'
export const reportCommandPath = '/report-command'
export const notificationListPath = '/notifications'
export const notificationDetailPath = '/notification/:salesDate'
export const genNotificationDetailPath = (salesDate: string): string =>
  `/notification/${salesDate}`
export const preCameraPath = '/camera/pre'
export const cameraGuidePath = '/camera/guide'
export const cameraPath = '/camera/take/:count'
export const genCameraPath = (count: number): string => `/camera/take/${count}`
export const genRecaptureCameraPath = (count: number): string =>
  `/camera/take/${count}?recapture=1`
export const cameraConfirmPath = '/camera/confirmation/all'
export const uploadCompletionPath = '/upload/complete'
export const inputSalesDataPath = `/sales-data/input`
export const inputSalesDataConfirmPath = `/sales-data/input/confirm`
export const submittedPath = '/submitted'
export const submittedWithDisagreementPath = '/submitted?disagreement=1'
export const registerPreCameraPath = `/register/camera/pre`
export const registerConfirmationPath = '/register/confirmation'
export const messageConfirmationPath = '/message/confirmation'
export const inputMessagePath = '/message/input'
export const inputMessageConfirmPath = '/message/confirm'
export const inputEmailPath = '/email/input'
export const inputEmailConfirmPath = '/email/confirm'
export const EmailSendCompletionPath = '/email/completion'
export const pcGuidePath = '/pc/guide'
