import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { bobaService } from '../services'
import { NotificationToTenant } from '../services/api'
import { AppThunkConfig } from '../../store'
import { ApiRequest, AppError } from '../../AppTypes'
import { OutOfServiceError, AuthorizationError } from '../services/errors'
import {
  outOfServiceAppErr,
  authenticationErrorAppError,
  internalServerErrorAppError,
} from './common'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'

const service = bobaService()

type NotificationState = {
  notifications: NotificationToTenant[]
  selectedNotification?: NotificationToTenant
} & ApiRequest

const initialState: NotificationState = {
  apiStatus: 'Initial',
  notifications: [],
  error: undefined,
}

const handleError = (err: Error): AppError => {
  if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  }
  if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const getNotifications = createAsyncThunk<
  NotificationToTenant[],
  void,
  AppThunkConfig
>('/notifications', async (_args, { getState, rejectWithValue }) => {
  try {
    return await service.getNotifications(getState())
  } catch (err) {
    return rejectWithValue(handleError(err))
  }
})

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotifications: (): NotificationState => {
      return {
        ...initialState,
      }
    },
    setSelectedNotification: (state, action): NotificationState => {
      const selectedNotification = action.payload
      return {
        ...state,
        selectedNotification,
      }
    },
    clearSelectedNotification: (state): NotificationState => {
      return {
        ...state,
        selectedNotification: undefined,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      disableLeaveConfirmation()
      const notifications = action.payload
      return {
        ...state,
        apiStatus: 'Success',
        notifications,
      }
    })
    builder.addCase(getNotifications.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  clearNotifications,
  setSelectedNotification,
  clearSelectedNotification,
} = notificationsSlice.actions

export default notificationsSlice
