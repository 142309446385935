import React from 'react'
import clsx from 'clsx'
import Icon from '../../atoms/Icon'
import styles from './style.module.scss'
import HorizontalTenantStoreName from '../../molecules/HorizontalTenantStoreName'
import Typography from '../../atoms/Typography'
import Button from '../../atoms/Button'

type AppHeaderProps = {
  tenantName: string
  storeName: string
  onClickGoNotificationList?: () => void
  onClickBackReportHistory?: () => void
}

type Props = AppHeaderProps & React.StyledProps
const AppHeader: React.StyledFC<Props> = ({
  className,
  style,
  tenantName,
  storeName,
  onClickGoNotificationList,
  onClickBackReportHistory,
}: Props) => {
  return (
    <div className={clsx(className, styles.container)} style={style}>
      <HorizontalTenantStoreName
        tenantName={`${tenantName} 様`}
        storeName={storeName}
        className={styles.text}
      />
      {onClickGoNotificationList && (
        <div
          className={styles.toNotificationButton}
          onClick={onClickGoNotificationList}
        >
          <Icon className={styles.icon} icon="mail" />
          <Typography className={styles.buttonTitle}>変更メッセージ</Typography>
        </div>
      )}
      {onClickBackReportHistory && (
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.backHistoryButton}
            variant="rounded"
            color="green"
            onClick={onClickBackReportHistory}
          >
            報告履歴にもどる
          </Button>
        </div>
      )}
    </div>
  )
}

export default AppHeader
