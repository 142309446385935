import globalAxios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { v4 as uuidv4 } from 'uuid'
import { BobaService } from '../BobaService'
import {
  Configuration,
  GeneralApi,
  FormAnswers,
  Forms,
  Images,
  ModelError,
  SalesDate,
  ConsistencyResult,
  SalesReportMessage,
  TrainingApi,
  TenantAndTenantSalesReportHistory,
  ConsistencyStatements,
  TenantUser,
  NotificationToTenant,
} from '../api'
import { RootState } from '../../../store'
import HttpStatusCode from './status'
import { ApiError, AuthorizationError, OutOfServiceError } from '../errors'

const sessionIdKey = 'x_session_id'

export class ApiBobaService implements BobaService {
  private api: GeneralApi

  private trainingApi: TrainingApi

  private sessionId: string | null

  private baseAxiosConfig = {
    headers: {
      'Access-Control-Allow-Credentials': '*',
    },
  }

  constructor(baseUrl: string) {
    this.api = new GeneralApi(
      new Configuration({ baseOptions: this.baseAxiosConfig }),
      baseUrl,
      this.createAxiosInstance()
    )
    this.trainingApi = new TrainingApi(
      new Configuration({ baseOptions: this.baseAxiosConfig }),
      baseUrl,
      this.createAxiosInstance()
    )
    this.sessionId = localStorage.getItem(sessionIdKey)
  }

  private createAxiosInstance(): AxiosInstance {
    const axios = globalAxios.create()
    const onFulfilled = this.handleResponseSuccess.bind(this)
    axios.interceptors.response.use(onFulfilled, (error) => {
      return Promise.reject(ApiBobaService.handleError(error))
    })
    axios.interceptors.request.use((config) => {
      const result = { ...config }
      // eslint-disable-next-line no-param-reassign
      result.headers = {
        ...(config.headers || {}),
        ...ApiBobaService.getRequestIdHeader(),
      }
      if (config.data) {
        result.data = decamelizeKeys(JSON.parse(config.data))
      }
      return result
    })
    axios.interceptors.response.use((response) => {
      if (response.data) {
        response.data = camelizeKeys(response.data)
      }
      return response
    })
    return axios
  }

  private handleResponseSuccess(
    v: AxiosResponse
  ): AxiosResponse | Promise<AxiosResponse> {
    if ('x-session-id' in v.headers) {
      this.sessionId = v.headers['x-session-id']
      localStorage.setItem(sessionIdKey, this.sessionId || '')
    }
    return v
  }

  async getActive(): Promise<void> {
    try {
      await this.api.getActive()
    } catch (err) {
      throw ApiBobaService.handleError(err)
    }
  }

  async getConsistencyStatements(
    state: RootState
  ): Promise<ConsistencyStatements> {
    this.existsSessionId()
    try {
      const response = await this.api.getConsistencyStatements(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.sessionId!,
        {
          headers: {
            ...this.getSessionHeader(),
            ...ApiBobaService.getBearerToken(state),
          },
        }
      )
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async getInputForm(state: RootState): Promise<Forms> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await this.api.getInputForm(this.sessionId!, {
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async getConsistencyResults(state: RootState): Promise<ConsistencyResult> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await this.api.getConsistencyResults(this.sessionId!, {
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async getNotifications(state: RootState): Promise<NotificationToTenant[]> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await this.api.getNotifications({
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async getTenantAuth(
    code: string
  ): Promise<[TenantAndTenantSalesReportHistory, string]> {
    const response = await this.api.getTenantAuth(code)
    const token = response.headers.authorization
    return [response.data, token]
  }

  async tenantUserSignUp(
    tenantUser: TenantUser,
    state: RootState
  ): Promise<void> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await this.api.tenantUserSignUp(tenantUser, {
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async postImages(
    images: Images,
    state: RootState,
    progressCallback: (progress: number) => void
  ): Promise<void> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await this.api.postImages(this.sessionId!, images, {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          progressCallback(percentCompleted)
          return percentCompleted
        },
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async postResentImages(
    images: Images,
    state: RootState,
    progressCallback: (progress: number) => void
  ): Promise<void> {
    this.existsSessionId()
    try {
      const response = await this.api.postResentImages(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.sessionId!,
        images,
        {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            progressCallback(percentCompleted)
            return percentCompleted
          },
          headers: {
            ...this.getSessionHeader(),
            ...ApiBobaService.getBearerToken(state),
          },
        }
      )
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async postInputForm(
    formAnswers: FormAnswers,
    state: RootState
  ): Promise<void> {
    try {
      const response = await this.api.postInputForm(formAnswers, {
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async postSalesDate(salesDate: SalesDate, state: RootState): Promise<void> {
    this.existsSessionId()
    try {
      const response = await this.api.postSalesDate(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.sessionId!,
        salesDate,
        {
          headers: {
            ...this.getSessionHeader(),
            ...ApiBobaService.getBearerToken(state),
          },
        }
      )
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async postAppSalesdateSalesDateIdRegisters(
    salesDateId: string,
    state: RootState
  ): Promise<void> {
    this.existsSessionId()
    try {
      const response = await this.api.postAppSalesdateSalesDateIdRegisters(
        salesDateId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.sessionId!,
        {
          headers: {
            ...this.getSessionHeader(),
            ...ApiBobaService.getBearerToken(state),
          },
        }
      )
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async postSubmit(state: RootState): Promise<void> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await this.api.postSubmit(this.sessionId!, {
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async postAppSalesdateRegisters(state: RootState): Promise<void> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await this.api.postAppSalesdateRegisters(this.sessionId!, {
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async putSalesReportMessage(
    message: SalesReportMessage,
    state: RootState
  ): Promise<void> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await this.api.putSalesReportMessage(this.sessionId!, message, {
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async putTrainingSubmit(state: RootState): Promise<void> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await this.trainingApi.putAppTrainingSubmit(this.sessionId!, {
        headers: {
          ...this.getSessionHeader(),
          ...ApiBobaService.getBearerToken(state),
        },
      })
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  async postTrainingInputForm(
    formAnswers: FormAnswers,
    state: RootState
  ): Promise<void> {
    this.existsSessionId()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await this.trainingApi.postAppTrainingInputForm(
        formAnswers,
        {
          headers: {
            ...this.getSessionHeader(),
            ...ApiBobaService.getBearerToken(state),
          },
        }
      )
      return response.data
    } catch (err) {
      throw ApiBobaService.handleError(err as AxiosError)
    }
  }

  private getSessionHeader(): object {
    return {
      'X-SESSION-ID': this.sessionId,
    }
  }

  private existsSessionId(): void {
    if (!this.sessionId) {
      throw AuthorizationError
    }
  }

  private static getBearerToken(state: RootState): object {
    const { auth } = state
    if (!auth || !auth.token) {
      throw new AuthorizationError('token was null')
    }
    return { Authorization: `Bearer ${auth.token}` }
  }

  private static getRequestIdHeader(): object {
    return {
      'X-Request-ID': uuidv4(),
    }
  }

  private static handleError(err: AxiosError<unknown>): Error {
    const code = err.response?.status
    const headers = err.response?.headers
    if (!code) {
      return err
    }
    const data = err.response?.data
    switch (code) {
      case HttpStatusCode.SERVICE_UNAVAILABLE:
        if (Object.keys(headers).length && headers['retry-after']) {
          const e = new OutOfServiceError(
            '時間外',
            new Date(headers['retry-after'])
          )
          return e
        }
        return new OutOfServiceError('時間外')
      default:
        if (!data) {
          return new ApiError(err.message, code, null)
        }
        if (ApiBobaService.implementsModelError(data)) {
          return new ApiError(err.message, code, data.code)
        }
        return new ApiError(err.message, code, null)
    }
  }

  private static implementsModelError(arg: unknown): arg is ModelError {
    return (
      typeof arg === 'object' &&
      arg !== null &&
      'code' in arg &&
      'message' in arg
    )
  }
}
