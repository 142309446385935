import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Div100vh from '../../components/atoms/Div100vh'
import useAppHistory from '../../utils/useAppHistory'
import { RootState } from '../../store'
import Notification from '../../components/molecules/Notification'
import AppHeader from '../../components/organisms/AppHeader'
import {
  genNotificationDetailPath,
  reportCommandPath,
} from '../../routes/paths'
import useTenant from '../hooks/useTenant'
import Typography from '../../components/atoms/Typography'
import { description, noNotificationDescription, title } from './messages'
import style from './styles.module.scss'
import { setSelectedNotification } from '../../features/network/notificationsSlice'

function NotificationList(): ReactElement {
  const history = useAppHistory()
  const dispatch = useDispatch()
  const tenant = useTenant()

  const { notifications } = useSelector(
    (state: RootState) => state.notifications
  )

  const handleButtonClick = (salesDate: string): void => {
    const selectedNotification = notifications.find(
      (notification) => notification.salesDate === salesDate
    )
    dispatch(setSelectedNotification(selectedNotification))
    history.push(genNotificationDetailPath(salesDate))
  }

  return (
    <Div100vh className={style.root} disabledModeWrapper>
      <AppHeader
        tenantName={tenant.tenantName}
        storeName={tenant.storeName}
        onClickBackReportHistory={(): void => history.push(reportCommandPath)}
      />
      <div className={style.titleWrapper}>
        <Typography variant="largeTitle" align="left" bold>
          {title}
        </Typography>
        <Typography
          className={style.description}
          variant="description"
          align="left"
        >
          {!notifications ? noNotificationDescription : description}
        </Typography>
      </div>
      {notifications &&
        notifications.map((notification) => (
          <Notification
            className={style.notifications}
            key={notification.salesDate}
            date={notification.salesDate}
            sendAt={notification.updatedAt}
            onClick={handleButtonClick}
          />
        ))}
    </Div100vh>
  )
}

export default NotificationList
