import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import useAppHistory from '../../utils/useAppHistory'
import { RootState } from '../../store'
import Div100vh from '../../components/atoms/Div100vh'
import AppHeader from '../../components/organisms/AppHeader'
import useTenant from '../hooks/useTenant'
import { notificationListPath, reportCommandPath } from '../../routes/paths'
import { clearSelectedNotification } from '../../features/network/notificationsSlice'
import style from './styles.module.scss'
import Typography from '../../components/atoms/Typography'
import { title } from './message'
import Space from '../../components/atoms/Space'
import Button from '../../components/atoms/Button'

function NotificationDetail(): ReactElement {
  dayjs.locale('ja')

  const history = useAppHistory()
  const dispatch = useDispatch()
  const tenant = useTenant()

  const { selectedNotification } = useSelector(
    (state: RootState) => state.notifications
  )

  const handleClickBackReportHistory = (): void => {
    dispatch(clearSelectedNotification())
    history.push(reportCommandPath)
  }

  const handleClickClose = (): void => {
    dispatch(clearSelectedNotification())
    history.push(notificationListPath)
  }

  return (
    <Div100vh className={style.root} disabledModeWrapper>
      <AppHeader
        tenantName={tenant.tenantName}
        storeName={tenant.storeName}
        onClickBackReportHistory={handleClickBackReportHistory}
      />
      <div className={style.container}>
        <Typography variant="largeTitle" align="left" bold>
          {title}
        </Typography>
        <Typography
          className={style.salesDate}
          variant="description"
          align="left"
        >
          売上日:
          {dayjs(selectedNotification?.salesDate).format('YYYY.MM.DD(ddd)')}
        </Typography>
        <Typography
          className={style.receivedAt}
          variant="description"
          align="left"
        >
          メッセージ受信日時:
          {dayjs(selectedNotification?.updatedAt).format(
            'YYYY.MM.DD(ddd) HH:mm'
          )}
        </Typography>
        <Space size="52px" />
        <Typography className={style.notification}>
          {selectedNotification?.content}
        </Typography>
        <Space size="120px" />
        <div className={style.closeButtonWrapper}>
          <Button color="white" onClick={handleClickClose}>
            確認して閉じる
          </Button>
        </div>
      </div>
    </Div100vh>
  )
}

export default NotificationDetail
