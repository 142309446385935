import React, { ClassAttributes, ReactElement } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import Top from '../pages/Top'
import Reader from '../pages/Reader'
import SalesDate from '../pages/SalesDate'
import ReportCommand from '../pages/ReportCommand'
import PreCamera from '../pages/PreCamera'
import {
  cameraConfirmPath,
  cameraGuidePath,
  cameraPath,
  inputSalesDataConfirmPath,
  inputSalesDataPath,
  preCameraPath,
  readerPath,
  salesDatePath,
  reportCommandPath,
  submittedPath,
  topPath,
  uploadCompletionPath,
  registerPreCameraPath,
  registerConfirmationPath,
  messageConfirmationPath,
  inputMessagePath,
  inputMessageConfirmPath,
  inputEmailPath,
  pcGuidePath,
  inputEmailConfirmPath,
  EmailSendCompletionPath,
  notificationListPath,
  notificationDetailPath,
} from './paths'
import ReceiptCamera from '../pages/ReceiptCamera'
import CameraConfirm from '../pages/CameraConfirmAll'
import UploadCompletion from '../pages/UploadCompletion'
import InputSalesData from '../pages/InputSalesData'
import InputSalesDataConfirm from '../pages/InputSalesDataConfirm'
import Submitted from '../pages/Submitted'
import NeedAuth from './needAuth'
import WithTitle from './withTitle'
import {
  titleCameraGuide,
  titleCapture,
  titleConfirmReceiptAll,
  titleQRCodeReader,
  titleReceiptUploadCompleted,
  titleSalesDataInput,
  titleSalesDataInputConfirm,
  titleSalesDate,
  titleReportCommand,
  titleSubmitted,
  titleTitlePre,
  titleTop,
  titleRegisterPreCamera,
  titleRegisterConfirmation,
  titleMessageConfirmation,
  titleMessageInput,
  titleMessageInputConfirm,
  titleInputEmail,
  titlePcGuide,
  titleConformEmail,
  titleEmailSendCompletion,
  titleNotificationList,
  titleNotificationDetail,
} from './titles'

import history from '../history'
import CameraGuide from '../pages/CameraGuide'
import RegisterPreCamera from '../pages/RegisterPreCamera'
import RegisterConfirmation from '../pages/RegisterConfirmation'
import MessageConfirmation from '../pages/MessageConfirmation'
import InputMessage from '../pages/InputMessage'
import InputMessageConfirm from '../pages/InputMessageConfirm'
import InputEmail from '../pages/InputEmail'
import PcGuide from '../pages/PcGuide'
import InputEmailConfirm from '../pages/InputEmailConfirm'
import EmailSendCompletion from '../pages/EmailSendCompletion'
import NotificationList from '../pages/NotificationList'
import NotificationDetail from '../pages/NotificationDetail'

const renderRoute = (): ReactElement => {
  return (
    <Router history={history}>
      <div className="SW-update-dialog" />
      <Switch>
        <Route
          exact
          path={topPath}
          render={(): ReactElement => (
            <WithTitle title={titleTop} Component={Top} />
          )}
        />
        <Route
          path={readerPath}
          render={(): ReactElement => (
            <WithTitle title={titleQRCodeReader} Component={Reader} />
          )}
        />
        <NeedAuth>
          <Route
            exact
            path={salesDatePath}
            render={(): ReactElement => (
              <WithTitle title={titleSalesDate} Component={SalesDate} />
            )}
          />
          <Route
            exact
            path={reportCommandPath}
            render={(): ReactElement => (
              <WithTitle title={titleReportCommand} Component={ReportCommand} />
            )}
          />
          <Route
            exact
            path={notificationListPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleNotificationList}
                Component={NotificationList}
              />
            )}
          />
          <Route
            exact
            path={notificationDetailPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleNotificationDetail}
                Component={NotificationDetail}
              />
            )}
          />
          <Route
            exact
            path={preCameraPath}
            render={(): ReactElement => (
              <WithTitle title={titleTitlePre} Component={PreCamera} />
            )}
          />
          <Route
            exact
            path={cameraGuidePath}
            render={(): ReactElement => (
              <WithTitle title={titleCameraGuide} Component={CameraGuide} />
            )}
          />
          <Route
            exact
            path={cameraConfirmPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleConfirmReceiptAll}
                Component={CameraConfirm}
              />
            )}
          />
          <Route
            exact
            path={uploadCompletionPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleReceiptUploadCompleted}
                Component={UploadCompletion}
              />
            )}
          />
          <Route
            path={cameraPath}
            render={(): ReactElement => (
              <WithTitle title={titleCapture} Component={ReceiptCamera} />
            )}
          />
          <Route
            exact
            path={inputSalesDataPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleSalesDataInput}
                Component={InputSalesData}
              />
            )}
          />
          <Route
            exact
            path={inputSalesDataConfirmPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleSalesDataInputConfirm}
                Component={InputSalesDataConfirm}
              />
            )}
          />
          <Route
            exact
            path={registerPreCameraPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleRegisterPreCamera}
                Component={RegisterPreCamera}
              />
            )}
          />
          <Route
            exact
            path={registerConfirmationPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleRegisterConfirmation}
                Component={RegisterConfirmation}
              />
            )}
          />
          <Route
            exact
            path={messageConfirmationPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleMessageConfirmation}
                Component={MessageConfirmation}
              />
            )}
          />
          <Route
            path={inputMessagePath}
            render={(): ReactElement => (
              <WithTitle title={titleMessageInput} Component={InputMessage} />
            )}
          />
          <Route
            path={inputMessageConfirmPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleMessageInputConfirm}
                Component={InputMessageConfirm}
              />
            )}
          />
          <Route
            exact
            path={submittedPath}
            render={(): ReactElement => (
              <WithTitle title={titleSubmitted} Component={Submitted} />
            )}
          />
          <Route
            path={inputEmailPath}
            render={(): ReactElement => (
              <WithTitle title={titleInputEmail} Component={InputEmail} />
            )}
          />
          <Route
            path={inputEmailConfirmPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleConformEmail}
                Component={InputEmailConfirm}
              />
            )}
          />
          <Route
            path={pcGuidePath}
            render={(): ReactElement => (
              <WithTitle title={titlePcGuide} Component={PcGuide} />
            )}
          />
          <Route
            path={EmailSendCompletionPath}
            render={(): ReactElement => (
              <WithTitle
                title={titleEmailSendCompletion}
                Component={EmailSendCompletion}
              />
            )}
          />
        </NeedAuth>
      </Switch>
    </Router>
  )
}

export default class DisableBackRouter extends React.Component {
  private currentPathname: string | null

  private currentSearch: string | null

  private alreadyShownAlert: boolean

  constructor(props: ClassAttributes<unknown>) {
    super(props)
    this.currentPathname = null
    this.currentSearch = null
    this.alreadyShownAlert = false
  }

  componentDidMount(): void {
    history.listen((newLocation, action) => {
      if (action === 'PUSH') {
        if (
          newLocation.pathname !== this.currentPathname ||
          newLocation.search !== this.currentSearch
        ) {
          // Save new location
          this.currentPathname = newLocation.pathname
          this.currentSearch = newLocation.search

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          })
          this.alreadyShownAlert = false
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1)
        if (!this.alreadyShownAlert) {
          this.alreadyShownAlert = true
          // eslint-disable-next-line no-alert
          alert(`画面を戻ることはできません

やめる場合は再度トップ画面の
URLからページを移動してください`)
        }
      }
    })

    // Prevent user from leaving, ignore for top page
    const onUnload = (e: BeforeUnloadEvent): void => {
      if (history.location.pathname !== '/') {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onUnload)
  }

  render(): ReactElement {
    return renderRoute()
  }
}
